import React, { Component } from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarTe from "../../components/layout/sidemenu/sidemenu_te"

import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'

import carbon_cycle from "../../images/teacher-resources/Two-Carbon-Cycle-Models_homepage_teacher.gif"

import Auth from '../../auth/auth'
import SignInOutButton from "../../components/auth/sign-in-out-button"

let auth
if (typeof window !== `undefined`) {
  auth = new Auth()
}

const Index = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: undefined
    }
  }

  componentDidMount() {
    const user = auth.getUser()
    if (user) {
      this.setState({user_email: user.email, authenticated: auth.isAuthenticated()})
    } else {
      this.setState({ authenticated: auth.isAuthenticated() })
    }
  }

  signin = (e) => {
    e.preventDefault()
    auth.signin()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  signout = (e) => {
    e.preventDefault()
    auth.signout()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  render() {
    return (
      <Layout location={this.props.location.pathname} >
        <SEO title="Carbon Connections - The Carbon Cycle and the Science of Climate" />
        <Container fluid className="mb-5">
          <Row>
            <Col md="auto">
              <SideBarTe location={this.props.location.pathname} />
              {console.log(this.props.location.pathname)}
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'Carbon Connections Teacher Resources'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header className="cardHeader">
                          <h1>
                            <span className="headerFontBlue">Carbon Connections Teacher Resources</span>
                          </h1>
                        </Card.Header>
                        {auth && !auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <div className="d-flex justify-content-center">
                              <SignInOutButton
                                signin={this.signin}
                                signout={this.signout}
                                authenticated={this.state.authenticated}
                              />
                            </div>
                          </Card.Body>
                        }
                        {auth && auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <div className="d-flex justify-content-center">
                              <div className="d-flex align-items-center">
                                <img
                                  src={ carbon_cycle }
                                  alt="Two Carbon Cycle Models"
                                  className="img-fluid mb-3"
                                />
                              </div>
                            </div>
                            <p>Welcome to the <strong>Carbon Connections</strong> teacher resources website! We are looking forward to working with you, as you and your students are our best critical friends. Your feedback plays a key role in helping us revise the materials.  We hope that you and your students find the process both rewarding and educational.</p>
                            <p>The supplementary materials here are our first effort to support you in your classroom. The materials include:</p>
                            <ul>
                              <li>Advanced preparation notes</li>
                              <li>Unit organizers</li>
                              <li>Lesson indicators and outcomes</li>
                              <li>Materials lists</li>
                              <li>Videos of select activities for teacher preparation</li>
                              <li>Answers to student-page questions</li>
                              <li>Student and teacher versions of lessons</li>
                              <li>Unit test or quiz questions</li>
                              <li>Classroom discussion PowerPoints</li>
                            </ul>
                            <p>But you and your students are our best critical friends. Please let us know as you have questions about the materials, or ideas for how to improve them. Your feedback plays a key role in helping us update and improve <b>Carbon Connections</b>. We hope that you and your students find the lessons interesting and educational.</p>
                          </Card.Body>
                        }
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Index
